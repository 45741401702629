<template>
  <!-- default container -->
  <div>
    <the-dealer-bar :activeMake="activeMake" :dealer="dealer"></the-dealer-bar>
    <the-recommended-models :modelStubData="modelStubData" :activeMake="activeMake"></the-recommended-models>
    <the-footer></the-footer>
  </div>
  <!-- end default container -->
</template>

<script>

import modelStubData from "@/assets/models.json";
import TheDealerBar from '@/components/TheDealerBar.vue';
import TheRecommendedModels from '@/components/TheRecommendedModels.vue';
import TheFooter from '@/components/TheFooter.vue';

export default {
  name: 'Videos',

  components: {
    TheDealerBar,
    TheRecommendedModels,
    TheFooter,
  },

  watch: {
    '$route.query.d': function () {
      this.getDealer();
    },
  },

  created() {

    if(!this.$route.query.mk) {
      this.setActiveMake('chrysler');
    } else {
      this.setActiveMake(this.$route.query.mk);
    }
    // this.setActiveTheme();
    this.setPageTitle();
    this.getDealer();
  },

  data() {
    return {
      dealerCode: this.$route.query.d,
      dealer: {},
      modelStubData: modelStubData,
      activeMake: '',
    };
  },

  methods: {

    getDealer() {
      var self = this;
      this.axios
        .post('https://data.dealervideos.com/v2.0/modelShowcase/GetModelShowcaseNavigationList', {
          programCode: process.env.VUE_APP_PROGRAM_CODE,
          dealerCode: self.dealerCode,
          campaignState: process.env.VUE_APP_CAMPAIGN_STATE,
        })
        .then(response => {
          self.dealer = response.data.modelShowcaseProgramPackage;
        })
        .catch(function() {
          window.location.href = "/not-found";
      });
    },

    setPageTitle() {
      document.title = 'Select a model to learn more';
    },

    setActiveMake(make) {
      let allowedMakes = ['chrysler','dodge','jeep','ram','fiat','alfa_romeo'];
      if(allowedMakes.includes(make)) {
        this.activeMake = make;
      } else {
        this.activeMake = 'chrysler';
      }

    },

    // setActiveTheme() {
    //   console.log('setting active theme...');
    //   this.$store.commit("setActiveTheme", this.stubModel);
    // },

    setStubModel() {
      this.stubModel = this.modelStubData.find(model => model.modelCode == this.$route.query.m);
      if(!this.stubModel) {
        window.location.href = '/model-select?d=' + this.$route.query.d;
      }
      this.stubModel.make = this.stubModel.make.toLowerCase();
    },

  },

  computed: {

  },

};
</script>